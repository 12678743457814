import AchievementBonus from './AchievementBonus.json'
import ActionsBarStyle, { name as ActionsBarName } from './ActionsBar'
import AmountControl from './AmountControl'
import AppLayout from './AppLayout.json'
import AtomActiveLinkStyle, { name as AtomActiveLink } from './AtomActiveLink'
import AtomBadge from './AtomBadge.json'
import AtomCard from './AtomCard.json'
import AtomDatePicker from './AtomDatePicker.json'
import AtomEmpty from './AtomEmpty.json'
import AtomGameCard from './AtomGameCard.json'
import AtomNavAccordionItemStyle, {
  name as AtomNavAccordionItemName,
} from './AtomNavAccordionItem'
import AtomSelect from './AtomSelect.json'
import AutoBetConfigStyle, { name as AutoBetConfigName } from './AutoBetConfig'
import BetButtomStyle, { name as BetButtomName } from './BetButton'
import BonusesNowIssued from './BonusesNowIssued.json'
import Button from './Button.json'
import Campaign from './Campaign.json'
import CashoutStyle, { name as CashoutName } from './Cashout'
import Checkbox from './Checkbox.json'
import CopyInput from './CopyInput.json'
import Drawer from './Drawer.json'
import FormError from './FormError.json'
import FormLabel from './FormLabel.json'
import GameTableStyle, { name as GameTableName } from './GameTable'
import Heading from './Heading.json'
import Input from './Input.json'
import InviteExplanation from './InviteExplanation.json'
import InviteGetCommission from './InviteGetCommission.json'
import InvitePartner from './InvitePartner.json'
import Link from './Link.json'
import LoginAndSignUp from './LoginAndSignUp.json'
import MinesSelectorStyle, { name as MinesSelectorName } from './MinesSelector'
import Modal from './Modal.json'
import Notification from './Notification.json'
import PersonalCenterProfileCard from './PersonalCenterProfileCard.json'
import PersonalCenterVipCard from './PersonalCenterVipCard.json'
import PinInput from './PinInput.json'
import PlinkAmountControlStyle, {
  name as PlinkAmountControlName,
} from './PlinkAmountControl'
import Popover from './Popover.json'
import Progress from './Progress.json'
import RankingHeadBoxStyle, {
  name as RankingHeadBoxName,
} from './RankingHeadBox'
import Slider from './Slider.json'
import StairsSelector from './StairsSelector.json'
import Stat from './Stat.json'
import SuccessModal from './SuccessModal.json'
import Switch from './Switch'
import Table from './Table.json'
import TableWithTabs from './TableWithTabs.json'
import Tabs from './Tabs.json'
import Tag from './Tag.json'
import Text from './Text.json'
import Tooltip from './Tooltip.json'
import TopBanner from './TopBanner.json'
import TotalProfitCard from './TotalProfitCard.json'
import VipInfo from './VipInfo.json'
import WheelProgressIcon from './WheelProgressIcon.json'
import WithdrawSelect from './WithdrawSelect.json'

const components = {
  AppLayout,
  [AtomActiveLink]: AtomActiveLinkStyle,
  AtomBadge,
  AtomCard,
  AtomDatePicker,
  AtomEmpty,
  Button,
  Campaign,
  CopyInput,
  Checkbox,
  Link,
  FormError,
  FormLabel,
  Heading,
  Modal,
  Tabs,
  Table,
  Slider,
  Stat,
  Input,
  InviteExplanation,
  InviteGetCommission,
  InvitePartner,
  AchievementBonus,
  BonusesNowIssued,
  TotalProfitCard,
  AtomGameCard,
  AtomSelect,
  PersonalCenterProfileCard,
  PersonalCenterVipCard,
  Progress,
  Popover,
  TableWithTabs,
  Tag,
  Tooltip,
  Text,
  TopBanner,
  LoginAndSignUp,
  Notification,
  AmountControl,
  [CashoutName]: CashoutStyle,
  [ActionsBarName]: ActionsBarStyle,
  [BetButtomName]: BetButtomStyle,
  [AutoBetConfigName]: AutoBetConfigStyle,
  [PlinkAmountControlName]: PlinkAmountControlStyle,
  [RankingHeadBoxName]: RankingHeadBoxStyle,
  [GameTableName]: GameTableStyle,
  [MinesSelectorName]: MinesSelectorStyle,
  [AtomNavAccordionItemName]: AtomNavAccordionItemStyle,
  Switch,
  PinInput,
  WithdrawSelect,
  SuccessModal,
  StairsSelector,
  VipInfo,
  Drawer,
  WheelProgressIcon,
}

export default components
