import type SemanticTheme from '@/styles/SemanticTheme'

import ButtonStyles from './Button.json'

const theme: SemanticTheme = {
  layoutGap: '15px',
  mainTextColor: 'gray.900',
  tabMaxWidth: 'auto',

  buttonNormalBg: ButtonStyles.baseStyle.colorScheme['fill-prim']['--btn-bg'],
  buttonNormalColor:
    ButtonStyles.baseStyle.colorScheme['fill-prim']['--btn-text'],

  switchNormalColor: 'prim.500',
  switchCheckedColor: 'seco.500',
  switchTextColor: 'gray.600',

  plinkoBG: [13, 18, 27],
  plinkoTagBg: [27, 34, 51],

  // mines
  minesStepBox: {
    border: '1px solid #fff',
    borderColor: 'gray.500',
  },
  minesStepBoxActive: {
    border: '1px solid #fff',
    borderColor: 'prim.500',
  },
  minesStepTop: {
    bgColor: 'gray.500',
    color: '#fff',
    border: 'none',
  },
  minesStepTopActive: {
    bgColor: 'prim.500',
    color: '#fff',
  },

  minesStepBottom: {
    bgColor: 'gray.400',
  },
  minesStepBottomActive: {
    bgColor: 'gray.400',
    color: 'prim.500',
  },

  starsStepBox: {
    border: '1px',
    borderColor: 'gray.500',
  },
  starsStepBoxActive: {
    border: '1px',
    borderColor: 'prim.500',
  },
  starsStepTop: {
    bgColor: 'gray.500',
    color: 'text.base',
    border: 'none',
  },
  starsStepTopActive: {
    bgColor: 'prim.500',
    color: 'white',
  },
  starsStepBottom: {
    color: 'text.base',
    bgColor: 'gray.400',
  },
  starsStepBottomActive: {
    color: 'prim.500',
    bgColor: 'gray.400',
  },
  autoPick: {
    flex: '1',
    h: '50px',
    borderRadius: '10px',
    overflow: 'hidden',
    layerStyle: 'ls-seco',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '48%',
    _disabled: {
      layerStyle: 'ls-tert',
      color: 'text.secondary',
    },
  },
  autoPickText: {
    fontWeight: '800',
    cursor: 'pointer',
    wordBreak: 'break-word',
    textAlign: 'center',
    lineHeight: '16px',
  },
  pickslot: {
    bgColor: 'gray.500',
    color: '#fff',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '48%',
    paddingLeft: '3px',
    paddingRight: '3px',
  },
  pickSlotText: {
    fontWeight: '800',
    cursor: 'pointer',
    wordBreak: 'keep-all',
    textAlign: 'center',
    lineHeight: '16px',
  },
  amountColor: 'prim.500',
}

export default theme
