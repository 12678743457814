import type { ComponentStyleConfig } from '@chakra-ui/react'

import { NAME, PARTS } from '@/modules/inhouse/logic/games/plinko/AmountContro'

const parts = [...PARTS]
const componentStyle: ComponentStyleConfig = {
  parts,
  baseStyle: {
    box: {
      bgColor: 'gray.400',
    },
    input: {
      color: 'prim.500',
    },
    switch: {
      bgColor: 'gray.500',
    },
    button: {
      bgColor: 'gray.500',
    },
  },
}

export const name = NAME
export default componentStyle
